import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { LocalStorageEnum } from '../localStorage.enum';

@Injectable({
	providedIn: 'root',
})
export class TownhallService {
	constructor(private tokenService: TokenService) {}

	public getTownhallDetails() {
		const request = this.tokenService.get('v1/townhall');
		return request;
	}

	public getTypeOfCity() {
		return this.tokenService.get('v1/townhall/valuesTypeOfCity');
	}

	public saveTownhallDetails(value) {
		const headers = new Headers({ 'Content-Type': 'application/json', charset: 'utf-8' });
		headers.append('Access-Control-Expose-Headers', 'authorization');
		headers.append('Authorization', localStorage.getItem(LocalStorageEnum.AUTH_TOKEN));
		return this.tokenService.post('v1/townhall', value, { headers: headers });
	}

	public saveLogoTownhall(formData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/townhall/logoimage', formData, { headers: headers });
	}
}
